import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./App.css";

const App = () => {
  return (
    <>
      <h1 className="text-center py-5">
        Welcome
      </h1>

      <p className="mt-4 mb-3 text-center">You can visit any of the below pages</p>
      <Navbar expand="lg" className='shadow-sm w-auto'>
        <Navbar.Collapse className="w-auto" id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Link className='nav-link text-decoration-underline' to="/">Home</Link>
            <Link className='nav-link text-decoration-underline' to={`/user/listing`}>Entity</Link>
            <Link className='nav-link text-decoration-underline' to={`/schemas/${window.location.host}/listing`}>Schemas</Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default App;

